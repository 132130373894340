import {ReactNode} from "react";
import {Typography} from 'antd';
import {BUY_PLAN_ROUTE, LOGIN_ROUTE, REGISTER_ROUTE, USER_DASHBOARD_ROUTE} from "../routes/route-path";

const {Link} = Typography

export interface FaqQuestion {
    key: number;
    question: string;
    answer: ReactNode;
}

export const faqQuestions: FaqQuestion[] = [
    {
        key: 1,
        question: "اشتراک بخرم همه ویدیوها برام قابل نمایش میشه؟",
        answer: "بله،  با خرید اشتراک در بازه زمانی که اشتراک فعال دارید،  می‌توانید تمام آموزش‌ها را به صورت آنلاین مشاهده کرده و جزوات آنها را دانلود نمایید."
    },
    {
        key: 2,
        question: "امکان دانلود ویدیوها وجود داره؟",
        answer: "خیر،  امکان دانلود ویدیوها وجود ندارد و فقط می‌توانید بعد از خرید اشتراک جزوات (اسلایدهای) آنها را دانلود نمایید."
    },
    {
        key: 3,
        question: "کد تخفیف از کجا بگیرم؟ ",
        answer: <>
            کد تخفیف در بازه‌های زمانی خاصی و در جشنواره‌های تخفیفی دانشگاهینو در اختیار شما عزیزان قرار میگیرد. جهت
            اطلاع از جشنواره‌ها و کدهای تخفیف، صفحه ما در اینستاگرام
            (<Link style={{fontSize:'16px', whiteSpace:'nowrap'}} href="https://www.instagram.com/math_daneshgahino" target="_blank">@math_daneshgahino</Link>)
            را فالو داشته باشید.
        </>
    },
    {
        key: 4,
        question: "در حال حاضر اشتراک فعال دارم، اگر مجددا حق اشتراک بخرم چه اتفاقی می افته؟",
        answer: <>
            در دانشگاهینو شما می‌توانید به صورت هم‌ زمان دو اشتراک داشته باشید،  یک اشتراک فعلی شما (اشتراک فعال) و یک اشتراک رزرو که بعد از اتمام اشتراک فعلی به صورت خودکار فعال می‌شود.  جهت خرید اشتراک رزرو کافیست
            <Link style={{fontSize:'16px', whiteSpace:'nowrap'}} href={BUY_PLAN_ROUTE}> فرآیند خرید </Link>
            اشتراک مورد نظر را یکبار دیگر انجام دهید.
        </>
    },
    {
        key: 5,
        question: "رزرو اشتراک به چه دردی میخوره؟",
        answer: "فرض کنید شما در حال حاضر یک اشتراک فعال دارید و به طور همزمان در جشنواره تخفیفی هستیم و یا کد تخفیف با تاریخ انقضا در اختیار دارید، در این شرایط می‌توانید یک اشتراک رزرو کرده از  تخفیفات موجود استفاده نمایید."
    },
    {
        key: 6,
        question: "چطور اشتراک بخرم؟",
        answer: <>
            در صفحه اول سایت بر روی قسمت
            (<Link style={{fontSize:'16px', whiteSpace:'nowrap'}} href={LOGIN_ROUTE}>ورود یا ثبت نام</Link>)
            بزنید سپس با تکمیل و ثبت اطلاعات مورد نیاز ثبت نام خود را کامل کرده و به
            <Link style={{fontSize:'16px', whiteSpace:'nowrap'}} href={BUY_PLAN_ROUTE}> صفحه خرید </Link>
            بروید. حال اشتراک مورد نظر خود را تهیه نمایید.
        </>
    },
    {
        key: 7,
        question: "از کجا بدونم چقدر از زمان اشتراکم باقی مونده؟",
        answer: <>
            ابتدا
            <Link style={{fontSize:'16px', whiteSpace:'nowrap'}} href={LOGIN_ROUTE}> وارد سایت </Link>
            شوید، از قسمت حساب کاربری بر روی
            (<Link style={{fontSize:'16px', whiteSpace:'nowrap'}} href={USER_DASHBOARD_ROUTE}>داشبورد کاربر</Link>)
            بزنید. در صفحه‌ای که وارد شده‌اید روی گزینه "اشتراک‌های من" بزنید و اشتراک‌های خود را مشاهده کنید.
        </>
    },
    {
        key: 8,
        question: "اشتراک فعال دارم اما ویدیوها برام قفله،  چیکار کنم؟",
        answer: <>
            برای مشاهده ویدیوها ابتدا از قسمت
            (<Link style={{fontSize:'16px', whiteSpace:'nowrap'}} href={LOGIN_ROUTE}>ورود یا ثبت نام</Link>)
            با وارد کردن نام کاربری و رمز عبور ورود کرده و بعد به مشاهده ویدیوها بپردازید.
        </>
    },
    {
        key: 9,
        question: "جزوات رو دانلود میکنم،  اما باز نمیشه،  چیکار کنم؟",
        answer: "حتما مطمئن باشید که روی تلفن همراه، لپ‌تاپ یا کامپیوتر شخصی خود، نرم افزارهای winzip یا winrar  جهت استخراج فایل‌ها از حالت فشرده و نرم افزاری برای خواندن (نمایش) فایل‌های pdf داشته باشید."
    },
    {
        key: 10,
        question: "چطور باید ثبت نام کنم؟",
        answer: <>
            در صفحه اول سایت وارد بخش
            (<Link style={{fontSize:'16px', whiteSpace:'nowrap'}} href={LOGIN_ROUTE}>ورود یا ثبت نام</Link>)
            شوید. سپس در وسط صفحه روی گزینه
            (<Link style={{fontSize:'16px', whiteSpace:'nowrap'}} href={REGISTER_ROUTE}>ثبت نام</Link>)
            بزنید. اطلاعات خود را وارد کرده و در انتها ثبت‌نام را تایید کنید.
        </>
    },
    {
        key: 11,
        question: "وارد حساب کاربریم شدم اما ویدیوها نمایش داده نمیشند، چیکار کنم؟",
        answer: <>
            ابتدا با ورود به منوی
            <Link style={{fontSize:'16px', whiteSpace:'nowrap'}} href={USER_DASHBOARD_ROUTE}> داشبورد کاربر </Link>
            ، مطمئن شوید که اشتراک فعال دارید، سپس مطمئن شوید که سرعت اینترنت شما مناسب است برای تست سرعت میتوانید از این لینک استفاده کنید
            (<Link style={{fontSize:'16px', whiteSpace:'nowrap'}} href="https://speedtest.net">speedtest.net</Link>)
            . همچنین کیفیت محتوای آموزشی را کمتر کرده و تست کنید. اگر مشکل حل نشد، جهت بررسی توسط تیم پشتیبانی برای ما پیغام بگذارید.
        </>
    },
    {
        key: 12,
        question: "کد فعال سازی/کد تایید برام پیامک نمیشه. چیکار کنم؟",
        answer: <>
            ارسال کد فعال‌سازی به اپراتور شما بستگی دارد. ابتدا مطمئن شوید پوشش مخابراتی خوبی دارید، سپس مطمئن شوید پیامک‌های تبلیغاتی خود را مسدود نکرده‌اید. لطفا بعد از انجام این مراحل چند دقیقه بعد مجدد تست کنید. همچنین در صورت امکان با شماره همراه دیگری تست کنید. اگر مشکل حل نشد، جهت بررسی مشکل پیغام بگذارید.
        </>
    },
]

export const paymentFaqQuestions: FaqQuestion[] = [
    {
        key: 1,
        question: "اشتراک بخرم همه ویدیوها برام قابل نمایش میشه؟",
        answer: "بله،  با خرید اشتراک در بازه زمانی که اشتراک فعال دارید،  می‌توانید تمام آموزش‌ها را به صورت آنلاین مشاهده کرده و جزوات آنها را دانلود نمایید."
    },
    {
        key: 2,
        question: "امکان دانلود ویدیوها وجود داره؟",
        answer: "خیر،  امکان دانلود ویدیوها وجود ندارد و فقط می‌توانید بعد از خرید اشتراک جزوات (اسلایدهای) آنها را دانلود نمایید."
    },
    {
        key: 3,
        question: "کد تخفیف از کجا بگیرم؟ ",
        answer: <>
            کد تخفیف در بازه‌های زمانی خاصی و در جشنواره‌های تخفیفی دانشگاهینو در اختیار شما عزیزان قرار میگیرد. جهت
            اطلاع از جشنواره‌ها و کدهای تخفیف، صفحه ما در اینستاگرام
            (<Link style={{fontSize:'16px', whiteSpace:'nowrap'}} href="https://www.instagram.com/math_daneshgahino" target="_blank">@math_daneshgahino</Link>)
            را فالو داشته باشید.
        </>
    },
    {
        key: 4,
        question: "در حال حاضر اشتراک فعال دارم، اگر مجددا حق اشتراک بخرم چه اتفاقی می افته؟",
        answer: <>
            در دانشگاهینو شما می‌توانید به صورت هم‌ زمان دو اشتراک داشته باشید،  یک اشتراک فعلی شما (اشتراک فعال) و یک اشتراک رزرو که بعد از اتمام اشتراک فعلی به صورت خودکار فعال می‌شود.  جهت خرید اشتراک رزرو کافیست
            <Link style={{fontSize:'16px', whiteSpace:'nowrap'}} href={BUY_PLAN_ROUTE}> فرآیند خرید </Link>
            اشتراک مورد نظر را یکبار دیگر انجام دهید.
        </>
    },
    {
        key: 5,
        question: "رزرو اشتراک به چه دردی میخوره؟",
        answer: "فرض کنید شما در حال حاضر یک اشتراک فعال دارید و به طور همزمان در جشنواره تخفیفی هستیم و یا کد تخفیف با تاریخ انقضا در اختیار دارید، در این شرایط می‌توانید یک اشتراک رزرو کرده از  تخفیفات موجود استفاده نمایید."
    }
]