import React, {useState} from 'react';
import {Menu, MenuProps} from 'antd';
import CSS from "csstype";
import {Menu as SMenu, MenuItem as SMenuItem, Sidebar} from "react-pro-sidebar";
import './dashboardSideMenu.css'
import {MenuMode} from "rc-menu/lib/interface";

type MenuItem = Required<MenuProps>['items'][number];

interface Props {
    items : MenuItem[];
    style ?: CSS.Properties;
    mode ?: MenuMode;
    selectedKey : string;
}

export const DashboardSideMenu = ({items, style, mode, selectedKey} : Props) => {

    const [collapsed, setCollapsed] = useState<boolean>(true);

    return (
        <Menu selectedKeys={[selectedKey]} style={style} mode={mode} items={items}/>
        // <div>
        //     <div className="dashboard-side-menu">
        //         <a style={{color:"#262626", fontSize:"30px", margin:"20px", cursor:"pointer", height:"100vh"}} onClick={event => setCollapsed(true)}>
        //             asdasd
        //             <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        //                 <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
        //                 <path d="M0 0h24v24H0z" fill="none"/>
        //             </svg>
        //         </a>
        //         <Menu defaultSelectedKeys={[defaultSelectedKey]} style={style} mode="inline" items={items}/>
        //     </div>
        //     <div className="mobile-dashboard-side-menu">
        //         <Sidebar style={{color:"#262626", position:"fixed", zIndex:"100", top:0, right:0, height:"100vh"}} collapsed={collapsed} collapsedWidth="0px" backgroundColor="#FFFFFF">
        //             <SMenu>
        //                 <a style={{color:"#262626", fontSize:"30px", margin:"20px", cursor:"pointer", height:"100vh"}} onClick={event => setCollapsed(true)}>
        //                     asdasd
        //                     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        //                         <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
        //                         <path d="M0 0h24v24H0z" fill="none"/>
        //                     </svg>
        //                 </a>
        //                 <SMenuItem>asd</SMenuItem>
        //                 {/*{*/}
        //                 {/*    items && items.map(item => {*/}
        //                 {/*            return (*/}
        //                 {/*                <SMenuItem>asd</SMenuItem>*/}
        //                 {/*            )*/}
        //                 {/*        }*/}
        //                 {/*    )*/}
        //                 {/*}*/}
        //             </SMenu>
        //         </Sidebar>
        //     </div>
        //
        // </div>
    );
}


