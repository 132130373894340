import React, {useEffect} from "react";
import {useSearchParams} from "react-router-dom";
import StorageService from "../../services/StorageService";

export const SaveRefCodeToStorage = () => {
    const [searchParams, setSearchParams] = useSearchParams()

    useEffect(() => {
        const ref = searchParams.get("ref");
        if (ref) {
            StorageService.setItem("ref", ref);
        }
    }, [searchParams])

    return(<></>);
}