import axios from 'axios';
import {genericApiCall} from "./UtilFunctions";

const baseUrl = process.env.REACT_APP_BASE_URL || "";

export interface Registration {
    firstName: string;
    lastName: string;
    mobile: string;
    password: string;
}

export interface Finalize {
    mobile: string;
    verificationCode: string;
    refAffiliateKey?: string;
}

export interface FinalizeForgetPassword {
    verificationCode: string;
    mobile: string;
    newPassword: string;
}

class RegistrationService {
    private static instance: RegistrationService;

    private constructor() {
    }

    public static getInstance(): RegistrationService {
        if (!RegistrationService.instance) {
            RegistrationService.instance = new RegistrationService();
        }
        return RegistrationService.instance;
    }

    async init(input: Registration): Promise<void> {
        await genericApiCall<void>(async () => {
            await axios.post<void>(baseUrl + "/public/v1/registration/init", input);
        });
    }

    async finalize(input: Finalize): Promise<void> {
        await genericApiCall<void>(async () => {
            await axios.post<void>(baseUrl + "/public/v1/registration/finalize", input);
        });
    }

    async initForgetPassword(mobile: string): Promise<void> {
        await genericApiCall<void>(async () => {
            await axios.post<void>(baseUrl + "/public/v1/registration/forget-password/init", {mobile});
        });
    }

    async finalizeForgetPassword(input: FinalizeForgetPassword): Promise<void> {
        await genericApiCall<void>(async () => {
            await axios.post<void>(baseUrl + "/public/v1/registration/forget-password/finalize", input);
        });
    }

}

export default RegistrationService.getInstance();