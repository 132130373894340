import {BrowserRouter as Router, Navigate, Route, Routes as Switch} from 'react-router-dom';

import {NotFoundPage} from '../pages/404';
import {PAGE_ROUTES} from './page-routes';
import {ScrollToTop} from './scroll-to-top';
import {PrivateRoute} from './private-route';
import {NOT_FOUND_ROUTE} from './route-path';
import React, {useEffect} from "react";
import CategoryService from "../services/CategoryService";
import {useDispatch} from "react-redux";
import {setCategory} from "../redux/categorySlice";
import {SaveRefCodeToStorage} from "../components/common/SaveRefCodeToStorage";

export function Routes() {

    const dispatch = useDispatch();

    const initialCategories = () => {
        CategoryService.getCategories().then(categories => dispatch(setCategory(categories)));
    }

    useEffect(() => {
        initialCategories();
    }, [])

    return (

        <Router>
            <SaveRefCodeToStorage/>
            <ScrollToTop/>
            <Switch>
                {PAGE_ROUTES.map(({id, isPrivate, deactivate, path, element}) =>
                    isPrivate || deactivate ? (
                        <PrivateRoute
                            key={id}
                            path={path}
                            element={element}
                            deactivate={deactivate}
                        />
                    ) : (
                        <Route key={id} path={path} element={element}/>
                    )
                )}
                <Route path={NOT_FOUND_ROUTE} element={<NotFoundPage/>}/>
                <Route path='*' element={<Navigate to={NOT_FOUND_ROUTE}/>}/>
            </Switch>
        </Router>

    );
}
