import axios from "axios";
import {genericApiCall, Page} from "./UtilFunctions";
import TokenService from "./TokenService";
import {PaymentGateway, TransactionStatus, TransactionType} from "../constans/enums";

const baseUrl = process.env.REACT_APP_BASE_URL || "";

export interface AdminTransactionOutputModel {
    mobileNumber : string;
    createdDate : Date;
    shaparakRefId : string;
    finalPrice : string;
    status : TransactionStatus;
    type : TransactionType;
    id : string;
    lastModifiedDate : Date;
    authority : string;
    userId : number;
    paymentGateway : PaymentGateway;
}

export interface TransactionOutputModel {
    createdDate : Date;
    shaparakRefId : string;
    finalPrice : string;
    status : TransactionStatus;
    type : TransactionType;
}

class TransactionService {

    private static instance: TransactionService;

    private constructor() {
    }

    public static getInstance(): TransactionService {
        if (!TransactionService.instance) {
            TransactionService.instance = new TransactionService();
        }
        return TransactionService.instance;
    }

    async getSelfTransactions(params:any): Promise<Page<TransactionOutputModel>> {
        return await genericApiCall<Page<TransactionOutputModel>>(async () => {
            const response = await axios.get<Page<TransactionOutputModel>>(baseUrl + "/api/v1/transaction/self", {
                params,
                headers: {
                    'Authorization': "Bearer " + await TokenService.getToken()
                }
            });
            return response.data;
        });
    }

    async getTransactions(params:any): Promise<Page<AdminTransactionOutputModel>> {
        return await genericApiCall<Page<AdminTransactionOutputModel>>(async () => {
            const response = await axios.get<Page<AdminTransactionOutputModel>>(baseUrl + "/api/v1/transaction", {
                params,
                headers: {
                    'Authorization': "Bearer " + await TokenService.getToken()
                }
            });
            return response.data;
        });
    }

}

export default TransactionService.getInstance();