import React, {useState} from "react";
import {Button, Checkbox, Col, Form, Input, Row, Select, Upload} from 'antd';
import {SECOND_COLOR} from "../../constans/colors";
import TextArea from "antd/es/input/TextArea";
import {useNavigate} from "react-router-dom";
import {openErrorNotification, openSuccessNotificationByMessage, toEnglishNumber} from "../../services/UtilFunctions";
import {Degree, DegreeTitle} from "../../services/EducationService";
import CooperationFormService from "../../services/CooperationFormService";
import {UploadOutlined} from "@ant-design/icons";
import {RcFile} from "antd/es/upload";
import {FieldOfStudy, FieldOfStudyTitle} from "../../constans/enums";
import backgroundImage1 from "../../assets/images/about-us-background-1.png";

const {Option} = Select;

export const CooperationForm = () => {

    return (
        <div>
            <div style={{
                height: "696px",
                backgroundImage: `url(${backgroundImage1})`,
                backgroundSize: "cover"
            }}>
                <div style={{padding:"0px 25px"}}>
                    <div className="container">
                        <Row gutter={[16, 16]} style={{height: "696px", textAlign: "center"}} align="middle">
                            <Col lg={0} md={24} sm={24} xs={24}>
                                <h1 style={{color: "#ffffff",
                                    fontSize: "40px",
                                    fontWeight: 900,}}>
                                    تدریس در دانشگاهینو
                                </h1>
                            </Col>
                            <Col lg={14} md={0} sm={0} xs={0}>
                                <div style={{position: "relative", bottom: "-130px"}}>
                                    <h1 style={{
                                        position: "absolute",
                                        bottom: "80px",
                                        right: "-15px",
                                        color: "#ffffff",
                                        fontSize: "61px",
                                        fontWeight: 900,
                                        zIndex: 4,
                                        whiteSpace:'nowrap'
                                    }}>تدریس در دانشگاهینو
                                    </h1>
                                    <div style={{
                                        position: "absolute",
                                        bottom: "25px",
                                        width: "545px",
                                        height: "80px",
                                        backgroundColor: "#2F80ED",
                                        zIndex: 1
                                    }}/>
                                    <div style={{
                                        position: "absolute",
                                        bottom: "100px",
                                        right: "-50px",
                                        width: "131px",
                                        height: "131px",
                                        backgroundColor: "#2D9CDB",
                                        zIndex: 2
                                    }}/>
                                    <div style={{
                                        position: "absolute",
                                        bottom: "85px",
                                        right: "-25px",
                                        width: "50px",
                                        height: "50px",
                                        borderTopRightRadius: "100%",
                                        backgroundColor: SECOND_COLOR,
                                        zIndex: 3
                                    }}/>
                                </div>
                            </Col>
                            <Col lg={10} md={24} sm={24} xs={24}>
                                <p style={{
                                    color: "#ffffff",
                                    fontSize: "1.5rem",
                                    backgroundColor: "rgba(0,0,0,0.5)",
                                    padding: "48px 32px",
                                    textAlign: "right",
                                    margin:0
                                }}>
                                    عاشق اشتراک دانش هستید؟ به جمع مدرسین دانشگاهینو بپیوندید!
                                    <br/>
                                    آیا شور و اشتیاق به آموزش در وجودتان ریشه دوانده است؟
                                    <br/>
                                    آیا تمایل دارید دانش و تخصص خود را با دیگران به اشتراک بگذارید و در مسیر ارتقای علم و دانش گام بردارید؟
                                </p>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
            <div style={{
                backgroundColor: "rgba(0, 0, 0, 0.70)",

            }}>
                <div style={{padding:"0px 20px"}}>
                    <div className="container" style={{position:"relative"}}>
                        <div style={{
                            textAlign: "right",
                            color: "#ffffff",
                            padding:"40px 0px",
                            fontSize:"1.5rem"
                        }}>
                            <p style={{margin:0}}>
                                دانشگاهینو، نخستین پلتفرم جامع VOD آموزشی ایران، به دنبال مدرسین توانمند و خلاق در تمامی رشته‌های دانشگاهی است.
                            </p>
                        </div>
                        <div style={{
                            position: "absolute",
                            bottom: "-15px",
                            right: "-15px",
                            width: "50px",
                            height: "50px",
                            borderBottomRightRadius: "100%",
                            backgroundColor: SECOND_COLOR,
                            zIndex: 3
                        }}/>
                        <div style={{
                            position: "absolute",
                            top: "-15px",
                            left: "-20px",
                            width: "50px",
                            height: "50px",
                            borderTopLeftRadius: "100%",
                            backgroundColor: "#56CCF2",
                            zIndex: 3
                        }}/>
                    </div>
                </div>
            </div>
            <div className="container">
                <p style={{color:"#333333", fontSize:"1.8rem", marginTop:"70px", fontWeight:'bold'}}>
                    اگر...
                </p>
                <ul style={{color:"#4F4F4F", fontSize:"1.5rem"}}>
                    <li>مهارت تدریس قوی و تسلط بر محتوای آموزشی دارید.</li>
                    <li>به تولید محتوای آموزشی جذاب و باکیفیت علاقه‌مند هستید.</li>
                    <li>علاقه‌مند به اشتراک دانش و تجربیات خود با دیگران هستید.</li>
                    <li>به دنبال یک فرصت شغلی چالش‌برانگیز و پویا هستید.</li>
                </ul>
                <p style={{color:"#333333", fontSize:"1.5rem"}}>
                    پس معطل نکنید!
                </p>
                <p style={{color:"#333333", fontSize:"1.5rem"}}>
                    فرم زیر را تکمیل کنید تا با شما بیشتر آشنا شویم و در یک گفتگوی دوستانه، مسیر همکاری را بررسی کنیم.
                </p>
                <p style={{color:"#333333", fontSize:"1.8rem", fontWeight:'bold'}}>
                    با پیوستن به جمع مدرسین دانشگاهینو:
                </p>
                <ul style={{color:"#4F4F4F", fontSize:"1.5rem"}}>
                    <li>به میلیون‌ها دانشجو در سراسر ایران دسترسی خواهید داشت.</li>
                    <li>فرصتی برای به اشتراک گذاشتن دانش و تخصص خود خواهید یافت.</li>
                    <li>در مسیر ارتقای سطح علمی کشور سهیم خواهید شد.</li>
                    <li>از مزایا و حمایت‌های متنوع دانشگاهینو بهره‌مند خواهید شد.</li>
                </ul>
            </div>
            <div className="container">
                <FormSection/>
            </div>


        </div>
    );
}

const FormSection = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [ file, setFile ] = useState<RcFile | null>();

    const justAllowEnglishNumbers = (e: React.ChangeEvent<HTMLInputElement>) => {
        let str = toEnglishNumber(e.target.value);
        form.setFieldValue(e.target.name, str.replace(/\D/g, ''))
    };

    return (
        <Form
            form={form}
            size='large'
            layout="vertical"
            onFinish={(values) => {
                if (!file){
                    openErrorNotification("لطفا رزومه خود را انتخاب کنید.");
                    return;
                }
                if (file && file.size / 1024 / 1024 > 5){
                    openErrorNotification("سایز فایل باید کمتر از ۵ مگابایت باشد.");
                    return
                }
                values = {...values, file:file}
                setLoading(true);
                CooperationFormService.add(values).then(value1 => {
                    openSuccessNotificationByMessage("درخواست شما ثبت شد.", 5);
                    form.resetFields();
                    setLoading(false);
                }).catch(reason => setLoading(false));
            }}
        >
            <Row gutter={[16, 16]}>
                <Col xs={24} sm={12}>
                    <Form.Item label="نام" name="firstName"
                               rules={[{required: true, message: 'این فیلد الزامی است.'}, {
                                   max: 100,
                                   message: "حداکثر کاراکتر 50 می باشد."
                               }]}>
                        <Input placeholder="نام"/>
                    </Form.Item>
                </Col>

                <Col xs={24} sm={12}>
                    <Form.Item label="نام خانوادگی" name="lastName"
                               rules={[{required: true, message: 'این فیلد الزامی است.'}, {
                                   max: 100,
                                   message: "حداکثر کاراکتر 50 می باشد."
                               }]}>
                        <Input placeholder="نام خانوادگی"/>
                    </Form.Item>
                </Col>

                <Col xs={24} sm={12}>
                    <Form.Item
                        name="mobile"
                        rules={[{required: true, message: 'لطفا شماره همراه خود را وارد کنید!'}]}
                        label="شماره همراه"
                    >
                        <Input name="mobile" onChange={justAllowEnglishNumbers} placeholder="شماره همراه"/>
                    </Form.Item>
                </Col>

                <Col xs={24} sm={12}>
                    <Form.Item label="ایمیل" name="email"
                               rules={[{required: true, message: 'این فیلد الزامی است.'}, {
                                   max: 200,
                                   message: "حداکثر کاراکتر 200 می باشد."
                               }]}>
                        <Input placeholder="ایمیل"/>
                    </Form.Item>
                </Col>

                <Col xs={24} sm={12}>
                    <Form.Item label="نام دانشگاه" name="university"
                               rules={[{required: true, message: 'این فیلد الزامی است.'}, {
                                   max: 200,
                                   message: "حداکثر کاراکتر 200 می باشد."
                               }]}>
                        <Input placeholder="نام دانشگاه"/>
                    </Form.Item>
                </Col>

                <Col xs={24} sm={12}>
                    <Form.Item
                        name="degree"
                        label="مدرک"
                    >
                        <Select allowClear>
                            <Option value={Degree.MASTER}>{DegreeTitle[Degree.MASTER]}</Option>
                            <Option value={Degree.DOCTORAL_STUDENT}>{DegreeTitle[Degree.DOCTORAL_STUDENT]}</Option>
                            <Option value={Degree.DOCTORAL}>{DegreeTitle[Degree.DOCTORAL]}</Option>
                            <Option value={null}>سایر</Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                    <Form.Item
                        name="fieldOfStudy"
                        label="رشته تحصیلی"
                        rules={[{required: true, message: 'این فیلد الزامی است.'}]}
                    >
                        <Select>
                            <Option value={FieldOfStudy.MATHEMATICS}>{FieldOfStudyTitle[FieldOfStudy.MATHEMATICS]}</Option>
                            <Option value={FieldOfStudy.COMPUTER_SCIENCE}>{FieldOfStudyTitle[FieldOfStudy.COMPUTER_SCIENCE]}</Option>
                            <Option value={FieldOfStudy.STATISTICS}>{FieldOfStudyTitle[FieldOfStudy.STATISTICS]}</Option>
                            <Option value={FieldOfStudy.PHYSICS}>{FieldOfStudyTitle[FieldOfStudy.PHYSICS]}</Option>
                            <Option value={FieldOfStudy.ENGINEERING_PHYSICS}>{FieldOfStudyTitle[FieldOfStudy.ENGINEERING_PHYSICS]}</Option>
                            <Option value={FieldOfStudy.APPLIED_CHEMISTRY}>{FieldOfStudyTitle[FieldOfStudy.APPLIED_CHEMISTRY]}</Option>
                            <Option value={FieldOfStudy.PURE_CHEMISTRY}>{FieldOfStudyTitle[FieldOfStudy.PURE_CHEMISTRY]}</Option>
                            <Option value={FieldOfStudy.CHEMICAL_ENGINEERING}>{FieldOfStudyTitle[FieldOfStudy.CHEMICAL_ENGINEERING]}</Option>
                            <Option value={FieldOfStudy.OTHER}>{FieldOfStudyTitle[FieldOfStudy.OTHER]}</Option>
                        </Select>
                    </Form.Item>
                </Col>

                <Col xs={24} sm={12}>
                    <Form.Item label="رزومه" rules={[{required: true, message: 'این فیلد الزامی است.'}]}>
                        <Upload
                            accept=".docx, .pdf, .doc"
                            maxCount={1}
                            beforeUpload={(file) => {
                                setFile(file);
                                return false;
                            }}
                            onRemove={(file) => {
                                setFile(null);
                            }}
                        >
                            <Button icon={<UploadOutlined />}>بارگذاری</Button>
                        </Upload>
                    </Form.Item>
                </Col>

                <Col xs={24} sm={12}>
                    <Form.Item label="سابقه تدریس دارم" name="teachingExperience" valuePropName="checked" initialValue={false}>
                        <Checkbox></Checkbox>
                    </Form.Item>
                </Col>

                <Col xs={24} sm={24}>
                    <Form.Item name="description" rules={[{
                        max: 1500,
                        message: "حداکثر کاراکتر 1500 می باشد."
                    }]} label="توضیحات"><TextArea placeholder="توضیحات" rows={10}/></Form.Item>
                </Col>

                <Form.Item>
                    <Button loading={loading} style={{width: "55px"}} type="primary"
                            htmlType="submit">ثبت</Button>
                </Form.Item>
            </Row>
        </Form>
    );
}