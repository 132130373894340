import React, {useState} from "react";
import {Drawer, Layout, MenuProps} from 'antd';
import {HOME_PAGE_ROUTE} from "../../routes/route-path";
import logo from "../../assets/images/logo1.png";
import {DashboardSideMenu} from "../../components/dashboard/DashboardSideMenu";
import {FORTH_COLOR, SEVENTH_COLOR, THIRD_COLOR} from "../../constans/colors";
import {UserPlans} from "../../components/dashboard/user/plans/UserPlans";
import {UserProfileContainer} from "../../components/dashboard/user/profile/UserProfileContainer";
import {NotificationContainer} from "../../components/notification";
import {useMedia} from "react-use";
import {MenuOutlined} from "@ant-design/icons";

type MenuItem = Required<MenuProps>['items'][number];

const {Header, Footer, Sider, Content} = Layout;

const headerStyle: React.CSSProperties = {
    textAlign: 'center',
    color: '#fff',
    height: 60,
    paddingInline: 50,
    lineHeight: '64px',
    padding:"0px 10px",
    backgroundColor: SEVENTH_COLOR,
};

function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group',
): MenuItem {
    return {
        key,
        icon,
        children,
        label,
        type,
    } as MenuItem;
}

export const UserDashboardPage = () => {

    const [activeComponent, setActiveComponent] = useState<React.ReactNode>(<UserProfileContainer/>)
    const isSmall = useMedia('(max-width: 768px)', true);
    const [isOpenStyledDrawer, setIsOpenStyledDrawer] = useState<boolean>(false);
    const [selectedKey, setSelectedKey] = useState<string>("key1");

    const items: MenuItem[] = [
        getItem((<a onClick={() => handleSetActiveComponent(<UserProfileContainer/>, "key1")}>پروفایل</a>), "key1", null),
        getItem((<a onClick={() => handleSetActiveComponent(<UserPlans/>, "key2")}>اشتراک های من</a>), "key2", null),
        // getItem((<a onClick={() => handleSetActiveComponent(<AffiliateLinkContainer/>, "key3")}>لینک معرفی</a>), "key3", null),
    ]

    const handleSetActiveComponent = (component : React.ReactNode, key : string) => {
        setActiveComponent(component);
        setIsOpenStyledDrawer(false);
        setSelectedKey(key);
    }

    const closeStyledDrawer = () => {
        setIsOpenStyledDrawer(false);
    }

    return (
        <Layout>
            {
                isSmall ?
                    <Drawer
                        onClose={closeStyledDrawer}
                        open={isOpenStyledDrawer}
                        placement="right"
                        destroyOnClose
                        width={340}
                    >
                        <Sider style={{
                            textAlign: 'right',
                            backgroundColor: FORTH_COLOR,
                            padding:"35px 5px",
                            fontSize:"30px",
                            overflow: 'hidden',
                        }} width={280}>
                            <DashboardSideMenu selectedKey={selectedKey} items={items} style={{fontSize:"18px",borderInlineEnd:"none"}} mode="inline"/>
                        </Sider>
                    </Drawer> :
                    <Sider style={{
                        textAlign: 'right',
                        backgroundColor: FORTH_COLOR,
                        padding:"35px 5px",
                        fontSize:"30px",
                        overflow: 'hidden',
                    }} breakpoint={"md"} width={280} collapsedWidth={50}>
                        <DashboardSideMenu selectedKey={selectedKey} items={items} style={{fontSize:"18px",borderInlineEnd:"none"}} mode="inline"/>
                    </Sider>
            }
            <Layout style={{minHeight:"100vh"}}>
                <Header style={headerStyle}>
                    {isSmall ? (
                        <div style={{float: "right"}}>
                            <MenuOutlined onClick={() => {
                                setIsOpenStyledDrawer(true);
                            }} style={{color:THIRD_COLOR, fontSize:'30px', verticalAlign:'middle', cursor:'pointer'}} />
                        </div>
                    ) : null}
                    <a href={HOME_PAGE_ROUTE}><img src={logo} alt="logo" style={{
                        height: "100%",
                        padding: "10px 16px",
                        float: "right"
                    }}/></a>
                    <span style={{color: THIRD_COLOR, float: "right", fontSize:"1.1rem"}}>دانشگاهینو / پنل کاربر</span>
                    <span style={{color: THIRD_COLOR, float: "left", fontSize:"1.1rem"}}>
                        <a style={{color: THIRD_COLOR}} href={HOME_PAGE_ROUTE}>بازگشت</a>
                    </span>
                </Header>
                <Content style={{marginTop: "2rem"}}>
                    {activeComponent}
                </Content>
            </Layout>
            <NotificationContainer/>
        </Layout>
    );
}