import axios from "axios";
import {genericApiCall, Page} from "./UtilFunctions";
import TokenService from "./TokenService";
import {Category} from "./CategoryService";
import {User} from "./UserService";
import {Plan} from "./PlanService";
import {UserPlanStatus} from "../constans/enums";

const baseUrl = process.env.REACT_APP_BASE_URL || "";

export interface UserPlan {
    id ?: number;
    title: string;
    user: User;
    plan: Plan;
    price : number;
    finalPrice : number;
    discountPercentage : number;
    shaparakRefId : string;
    startDate: Date;
    endDate: Date;
    active:boolean;
    status:UserPlanStatus;
}

export interface UserPlanTotalReportModel {
    totalPrice: number;
    count: number;
    planReports: PlanReport[];
}

export interface PlanReport {
    planId: number;
    planTitle: string;
    totalPrice: number;
    count: number;
    planPriceReports: PlanPriceReport[];
}

export interface PlanPriceReport {
    planId: number;
    planTitle: string;
    finalPrice: number;
    totalPrice: number;
    count: number;
}

class UserPlanService {

    private static instance: UserPlanService;

    private constructor() {
    }

    public static getInstance(): UserPlanService {
        if (!UserPlanService.instance) {
            UserPlanService.instance = new UserPlanService();
        }
        return UserPlanService.instance;
    }

    async getUserPlansForAdmin(params : any): Promise<Page<UserPlan>> {
        return await genericApiCall<Page<UserPlan>>(async () => {
            const response = await axios.get<Page<UserPlan>>(baseUrl + "/api/v1/user-plans", {
                params,
                headers: {
                    'Authorization': "Bearer " + await TokenService.getToken()
                }
            });
            return response.data;
        });
    }

    async getTotalReportForAdmin(params : any): Promise<UserPlanTotalReportModel> {
        return await genericApiCall<UserPlanTotalReportModel>(async () => {
            const response = await axios.get<UserPlanTotalReportModel>(baseUrl + "/api/v1/user-plans/total-report", {
                params,
                headers: {
                    'Authorization': "Bearer " + await TokenService.getToken()
                }
            });
            return response.data;
        });
    }

    async getActivePlansSelfPage(params : any): Promise<Page<UserPlan>> {
        return await genericApiCall<Page<UserPlan>>(async () => {
            const response = await axios.get<Page<UserPlan>>(baseUrl + "/api/v1/user-plans/actives/self", {
                params,
                headers: {
                    'Authorization': "Bearer " + await TokenService.getToken()
                }
            });
            return response.data;
        });
    }

    async getPlansSelfPage(params : any): Promise<Page<UserPlan>> {
        return await genericApiCall<Page<UserPlan>>(async () => {
            const response = await axios.get<Page<UserPlan>>(baseUrl + "/api/v1/user-plans/self", {
                params,
                headers: {
                    'Authorization': "Bearer " + await TokenService.getToken()
                }
            });
            return response.data;
        });
    }

    async checkActivePlan(): Promise<boolean> {
        const token = await TokenService.getOptionalToken();
        if (token){
            return await genericApiCall<boolean>(async () => {
                const response = await axios.get<boolean>(baseUrl + "/api/v1/user-plans/actives/self/check", {
                    headers: {
                        'Authorization': "Bearer " + token
                    }
                });
                return response.data;
            });
        }else {
            return false;
        }

    }

    async delete(id : number): Promise<void> {
        return await genericApiCall<void>(async () => {
            const response = await axios.delete<void>(baseUrl + "/api/v1/user-plans/" + id, {
                headers: {
                    'Authorization': "Bearer " + await TokenService.getToken()
                }
            });
            return response.data;
        });
    }

    async getNoPaidPlansPrice(): Promise<number> {
        return await genericApiCall<number>(async () => {
            const response = await axios.get<number>(baseUrl + "/api/v1/user-plans/no-paid-plans/price", {
                headers: {
                    'Authorization': "Bearer " + await TokenService.getToken()
                }
            });
            return response.data;
        });
    }

}

export default UserPlanService.getInstance();