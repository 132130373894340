import {Carousel} from 'antd';
import React from "react";
import daneshgahino from '../../assets/images/daneshgahino.jpg';

export const MainSlider = () => {

    return (
        <div className="ltr" style={{
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingRight: '0px',
            paddingLeft: '0px',
            marginTop: '35px'
        }}>
            <Carousel effect="scrollx" swipeToSlide draggable autoplay>
                {/*<div>*/}
                {/*    <img src={banner2} style={{width:'100%'}}/>*/}
                {/*</div>*/}
                {/*<div>*/}
                {/*    <img src={teacherHiring} style={{width:'100%'}}/>*/}
                {/*</div>*/}
                <div>
                    <img src={daneshgahino} style={{width:'100%'}}/>
                </div>
            </Carousel>

        </div>
    );

}