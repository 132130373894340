import React from 'react';
import {useSelector} from "react-redux";
import {RootState} from "../../redux/reduxStore";
import {Category} from "../../services/CategoryService";
import {Col, Menu, type MenuProps, Row, Space} from "antd";
import {
    ABOUT_US_ROUTE,
    BUY_PLAN_ROUTE, CATEGORY_COURSES_ROUTE,
    CONTACT_US_ROUTE,
    COOPERATION_FORM_ROUTE, FAQ_ROUTE,
    HOME_PAGE_ROUTE, TEACHERS_ROUTE
} from "../../routes/route-path";
import {SearchInput} from "../new-search-input";
import {Login} from "./Login";
import {ContactUs} from "./ContactUs";
import {HorizontalCategoryNavigation} from "../category-navigation/new-horizontal";
import logo from '../../assets/images/horizontal-logo.png';
import {User} from "../../services/UserService";
import {ShoppingCart} from "./ShoppingCart";
import {THIRD_COLOR} from "../../constans/colors";

const colStyle: any = {};

interface Props {
    profile:User | undefined;
}

const mostUsedItemStyle = {fontSize:"12px", lineHeight:"10px", color:'#262626'};

export const Header = ({profile} : Props) => {

    const categories: Category[] = useSelector((state: RootState) => state.categories);
    const shoppingCart: number[] = useSelector((state: RootState) => state.cart);

    const getCartCount = () => {
        return shoppingCart.length;
    }

    return (
        <>
            <div style={{
            }}>
                <div className="container">
                    <Row gutter={[35, 16]} align='middle'>
                        <Col span={24} className="ltr">
                            <Space size={25} style={{display:'flex', justifyContent:'start', lineHeight:'5px', padding: "10px 0px"}}>
                                <a style={mostUsedItemStyle} href={ABOUT_US_ROUTE}>درباره ما</a>
                                <a style={mostUsedItemStyle} href={CONTACT_US_ROUTE}>تماس با ما</a>
                                <a style={mostUsedItemStyle} href={COOPERATION_FORM_ROUTE}>تدریس در دانشگاهینو</a>
                                <a style={mostUsedItemStyle} href={TEACHERS_ROUTE}>تیم آموزشی دانشگاهینو</a>
                                <a style={mostUsedItemStyle} href={FAQ_ROUTE}>سوالات متداول</a>
                            </Space>

                        </Col>
                    </Row>
                </div>
            </div>
            <div style={{
                borderBottom: '1px solid #e0e0e0',
                borderTop: '1px solid #e0e0e0',
                boxShadow: '0px 1px 8px 0px #00000038'
            }}>
                <div className="container" style={{paddingTop: "15px"}}>
                    <Row gutter={[35, 16]} align='middle'>
                        <Col flex="170px" style={colStyle}>
                            <a href={HOME_PAGE_ROUTE} style={{width: "160px"}}><img style={{verticalAlign: "middle"}}
                                                                                    src={logo}
                                                                                    alt="logo"/></a>
                        </Col>
                        <Col flex="auto" style={colStyle}>
                            <SearchInput/>
                        </Col>
                        <Col flex="178px" style={{...colStyle}}>
                            <Login profile={profile}/>
                        </Col>
                        <Col flex="42px" style={{...colStyle}}>
                            <a href={BUY_PLAN_ROUTE}>
                                <ShoppingCart/>
                            </a>
                        </Col>
                        <Col flex="148px" style={{...colStyle}}>
                            <ContactUs/>
                        </Col>
                        <Col span={24}>
                            <div style={{display: "flex", justifyContent: "center"}}>
                                <HorizontalCategoryNavigation categories={categories}/>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
}